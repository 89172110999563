import Cookie from 'js-cookie'
import jwt_decode from "jwt-decode";

import { TIERS } from '../config/tiers'


export function isCookieValid() {
  const user = getUser();
  const date = new Date();

  try {
  // const date = new Date("December 30, 2023 11:20:25");
    const expDate = new Date(user.access.expires);

    return expDate>date;
  } catch (e) {
    return false;
  }
}

export function getConfirmLogin() {
  return Cookie.get('isConfirmLogin')
}

export function setConfirmLogin() {
  Cookie.set('isConfirmLogin', 'confirm', { expires: 30 })
}

export function removeConfirmLogin() {
  if (getConfirmLogin()) {
    Cookie.remove('isConfirmLogin')
  }
}

export function getUser() {
  const user = Cookie.get('user')

  try {
    return JSON.parse(user)
  } catch (e) {
    return {}
  }
}

export function setUser(user, rememberMe) {
  if (user) {
    const expires = rememberMe ? 30 : 1
    Cookie.set('user', JSON.stringify(user), { expires })
  }
}

export function getUserTier() {
  const { access } = getUser();

  if (access?.token) {
    const decoded = jwt_decode(access.token);
    return TIERS[decoded.tir];
  } else {
    return TIERS[0];
  }
}

export function removeUser() {
  if (getUser()) {
    Cookie.remove('user')
  }
}

export function getRememberMeData() {
  const flag = Cookie.get('isRememberMe')

  try {
    return JSON.parse(flag)
  } catch (e) {
    return null
  }
}

export function setRememberMeData(flag) {
  Cookie.set('isRememberMe', JSON.stringify(flag), { expires: 30 })
}

export function removeRememberMeData() {
  if (getRememberMeData()) {
    Cookie.remove('isRememberMe')
  }
}

export function getActiveProjectId() {
  const id = Cookie.get('projectId')

  try {
    return JSON.parse(id)
  } catch (e) {
    return null
  }
}

export function getActiveProjectWallet() {
  const id = Cookie.get('projectWallet')

  try {
    return JSON.parse(id)
  } catch (e) {
    return null
  }
}

export function setActiveProjectId(id) {
  console.log("setActiveProjectId")
  // console.log(id)
  Cookie.set('projectId', JSON.stringify(id), { expires: 30 })
}

export function setActiveProjectWallet(id) {
  Cookie.set('projectWallet', JSON.stringify(id), { expires: 30 })
}

export function removeActiveProjectId() {
  if (getActiveProjectId()) {
    Cookie.remove('projectId')
  }
}

export function getActiveContractIds() {
  const id = Cookie.get('contractId')

  try {
    return JSON.parse(id)
  } catch (e) {
    return null
  }
}

export function setActiveContractIds(id) {
  console.log("setActivesetActiveContractIdsrojectId")
  console.log(id)
  Cookie.set('contractId', JSON.stringify(id), { expires: 30 })
}

export function getPinnedGroups() {
  const id = Cookie.get('pinned')

  try {
    return JSON.parse(id)
  } catch (e) {
    return []
  }
}

export function addPinnedGroups(id) {
  console.log("setPinnedGroupsd")
  console.log(id)
  let pinned = getPinnedGroups();
  console.log(pinned)
  pinned.push(id);
  Cookie.set('pinned', JSON.stringify(pinned), { expires: 2147483647 })

  console.log(pinned)
  return pinned;
}

export function removePinnedGroups(id) {
  console.log("setPinnedGroupsd")
  console.log(id);
  let pinned = getPinnedGroups();
  console.log(pinned)
  const index = pinned.indexOf(id);
  if (index > -1) { // only splice array when item is found
    pinned.splice(index, 1); // 2nd parameter means remove one item only
  }
  console.log(pinned)
  Cookie.set('pinned', JSON.stringify(pinned), { expires: 2147483647 })

  return pinned;
}
