import HelpIcon from '@mui/icons-material/Help'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Link, useLocation } from 'react-router-dom'

import Logo from '../../../assets/img/logo-icon.png'
import ProfileMenu from '../../../components/TopBar/ProfileMenu'
import { LeftInfo, MainHeader, RightInfo } from '../styles'
import ProjectSelector from './ProjectSelector'

const PortalHeader = () => {
  const location = useLocation()
  const { pathname } = location

  if (pathname.includes('/collection')) {
    return null
  }
  if (pathname.includes('/nft')) {
    return null
  }

  return (
    <div>
      <MainHeader>
        <LeftInfo>
          <Link to="/landing">
            <img alt="blocksee" src={Logo} />
          </Link>
          <ProjectSelector isReset={pathname === '/landing'} />
        </LeftInfo>
        <RightInfo>
          <span></span>
          <ProfileMenu />
          {/* {pathname !== '/landing' && (
            <Link to="/notification">
              <NotificationsIcon sx={{ fontSize: 37 }} />
            </Link>
          )} */}
          <Link to="/help">
            <HelpIcon sx={{ fontSize: 36 }} />
          </Link>
        </RightInfo>
      </MainHeader>
    </div>
  )
}

export default PortalHeader
