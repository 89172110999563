import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'

const BpIcon = styled('span')(({ _theme, isWhite }) => ({
  borderRadius: 2,
  width: 15,
  height: 15,
  border: `2px solid ${isWhite ? 'var(--white)' : 'var(--bunting)'}`,
}))

const BpCheckedIcon = styled('span')(({ _theme, isWhite }) => ({
  borderRadius: 2,
  width: 15,
  height: 15,
  border: `2px solid ${isWhite ? 'var(--white)' : 'var(--bunting)'}`,

  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    marginTop: -3,
    marginLeft: -2,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23f97fc4'/%3E%3C/svg%3E\")",
    content: '""',
  },
}))

const checkboxStyle = {
  background: 'var(--white)',
  marginLeft: 0,
  padding: 0,
  maxWidth: 15,
  maxHeight: 15,

  '& svg': {
    fontSize: 21,
  },

  '&:hover': {
    background: 'var(--whisper)',
  },
}

const CustomCheckBox = (props) => {
  const { isWhite, ...rest } = props

  return (
    <Checkbox
      onClick={props.onClick}
      sx={checkboxStyle}
      icon={<BpIcon isWhite={props?.isWhite} />}
      checkedIcon={<BpCheckedIcon isWhite={props?.isWhite} />}
      {...rest}
    />
  )
}

export default CustomCheckBox
