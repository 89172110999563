import { Navigate, Outlet } from 'react-router-dom'

import { isCookieValid } from '../../utils/cookie'
import { Root } from './styles'

const AuthLayout = () => {
  // const isAuthenticated = isCookieValid();

  // if (isAuthenticated) {
  //   return <Navigate to="/landing" />
  // }

  return (
    <Root>
      <Outlet />
    </Root>
  )
}

export default AuthLayout
