import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { isCookieValid } from '../../utils/cookie'
import PortalHeader from './PortalHeader'
import { MainView, Root } from './styles'

const PortalLayout = () => {
  const location = useLocation()
  const isAuthenticated = isCookieValid()

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return (
    <Root>
      <PortalHeader />
      <MainView>
        <Outlet />
      </MainView>
    </Root>
  )
}

export default PortalLayout
