export const TIERS = [
    {
        leadgen: 1,
        project: 100,
        drops: 200,
    },
    {
        leadgen: 3,
        project: 5000,
        drops: 10000,
    },
    {
        leadgen: 10,
        project: 25000,
        drops: 50000,
    },
    {
        leadgen: 20,
        project: 100000,
        drops: 250000,
    },
];