import 'react-toastify/dist/ReactToastify.min.css'
import './assets/scss/app.scss'

import * as Sentry from "@sentry/react";
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import App from './App'
import reportWebVitals from './reportWebVitals'

Sentry.init({
  dsn: "https://d3d82d74dbadcf56770d44bc3a4abde5@o4504884341571584.ingest.sentry.io/4505840683712512",
  integrations: [
    new Sentry.BrowserTracing({}),
    new Sentry.Replay(),
  ],
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <BrowserRouter>
    <App />
    <ToastContainer newestOnTop className="custom-notification" />
  </BrowserRouter>
)

reportWebVitals()
