import { createGlobalstate } from 'state-pool'

export const projectsStore = createGlobalstate([])
export const globalProject = createGlobalstate()

export const addToListStore = createGlobalstate([])

export const addToAirdropStore = createGlobalstate([])

export const changeMintUriId = createGlobalstate([])

// contains the list of [ nft collection contract address and the list of all nft ids in the collection that can be airdropped ]
export const addToAirdropNftList = createGlobalstate([])
