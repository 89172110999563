import loadable from '@loadable/component'
import { Navigate, Route, Routes } from 'react-router-dom'

import { AuthLayout } from './containers/AuthLayout'
import { PortalLayout } from './containers/PortalLayout'
import { isCookieValid } from './utils/cookie'

// Page containers
const Login = loadable(() => import('./containers/Login'))
const ForgotPassword = loadable(() => import('./containers/Password/ForgotPassword'))
const ResetPassword = loadable(() => import('./containers/Password/ResetPassword'))
const Register = loadable(() => import('./containers/Register'))
const EmailConfirm = loadable(() => import('./containers/Verification/EmailConfirm'))
const SetPassword = loadable(() => import('./containers/Verification/SetPassword'))
const ConnectWallet = loadable(() => import('./containers/Verification/ConnectWallet'))
const AddCompanyData = loadable(() => import('./containers/Verification/AddCompanyData'))
const NewProject = loadable(() => import('./containers/NewProject'))
const NewContract = loadable(() => import('./containers/NewContract'))
const NewList = loadable(() => import('./containers/NewList'))
const NewEvent = loadable(() => import('./containers/NewEvent'))
const Landing = loadable(() => import('./containers/Landing'))
const Dashboard = loadable(() => import('./containers/Dashboard/Dashboard'))
const Mint = loadable(() => import('./containers/Dashboard/Mint'))
const Contract = loadable(() => import('./containers/Dashboard/Contract'))
const Help = loadable(() => import('./containers/Help'))
const AccountSettings = loadable(() => import('./containers/AccountSettings'))
const UserProfile = loadable(() => import('./containers/UserProfile'))
const ContractProfile = loadable(() => import('./containers/ContractProfile'))
const CollectionItem = loadable(() => import('./containers/CollectionItem'))
const CollectionsList = loadable(() => import('./containers/CollectionsList'))
const Communications = loadable(()=>import('./containers/Dashboard/Communications'))
const Tickets = loadable(() => import('./containers/Dashboard/Tickets'))
const TicketsManager = loadable(() => import('./containers/Dashboard/TicketsManager'))
const EventScanner = loadable(() => import('./containers/EventScanner'))
const Pricing = loadable(() => import('./containers/Pricing'))
const PaymentCanceled = loadable(() => import('./containers/PaymentCanceled'))
const PaymentSuccess = loadable(() => import('./containers/PaymentSuccess'))

function App() {
  const isAuthenticated = isCookieValid()

  return (
    <Routes>
      <Route path="/" element={<Navigate to={isAuthenticated ? '/landing' : '/login'} />} />
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="register" element={<Register />} />
        <Route path="email-verification" element={<EmailConfirm />} />
        <Route path="set-password" element={<SetPassword />} />
        <Route path="connect-wallet" element={<ConnectWallet />} />
        <Route path="add-company" element={<AddCompanyData />} />
      </Route>
      <Route path="new-project" element={<NewProject />} />
      <Route path="new-contract" element={<NewContract />} />
      <Route path="new-list" element={<NewList />} />
      <Route path="new-event" element={<NewEvent />} />
      <Route element={<PortalLayout />}>
        <Route path="landing" element={<Navigate to={'/dashboard'} />} />
        {/* <Route path="landing" element={<Landing />} /> */}
        <Route path="dashboard/:tab" element={<Dashboard />} />
        <Route path="dashboard" element={<Navigate to={'/dashboard/overview'} />} />
        <Route path="user/:id" element={<UserProfile />} />
+       <Route path="tools/chat" element={<Communications />}/>
        <Route path="contract/:id" element={<ContractProfile />} />
        <Route path="mint/:id" element={<Mint />} />
        <Route path="mycollection/:id/:type" element={<Contract />} />
        <Route path="help" element={<Navigate to={'/help/support/general-inquiry'} replace />} />
        <Route
          path="help/tutorials"
          element={<Navigate to={'/help/tutorials/create-your-first-project'} replace />}
        />
        <Route path="help/:tab/:tabItem" element={<Help />} />
        <Route
          path="account-settings"
          element={<Navigate to={'/account-settings/account'} replace />}
        />
        <Route path="account-settings/:tab" element={<AccountSettings />} />
        <Route path="collection/:chain/:contract/:wallet" element={<CollectionsList />} />
        <Route path="nft/:chain/:contract/:tokenId" element={<CollectionItem />} />
        <Route path="tickets/single/:event/:id" element={<Tickets />} />
        <Route path="tickets/manager/:id" element={<TicketsManager />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="payment/success/:sessionid" element={<PaymentSuccess />} />
        <Route path="payment/canceled" element={<PaymentCanceled />} />
      </Route>
      <Route path="event/scan/:eventid" element={<EventScanner />} />
    </Routes>
  )
}

export default App
