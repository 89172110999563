import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

const CustomLoadingBar = ({ isFullScreen, size }) => {
  const fullScreenStyle = isFullScreen
    ? {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 0,
        background: 'rgba(0, 0, 0, 0.4)',
      }
    : {}
  const normalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 9999,
  }

  return (
    <Box sx={{ ...normalStyle, ...fullScreenStyle }} my={2}>
      <div style={{ marginTop: '1rem' }}>Loading data. This might take a while.</div>
      <div style={{ marginTop: '1rem' }}><CircularProgress size={size} /></div>
    </Box>
  )
}

export default CustomLoadingBar
