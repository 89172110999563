import api, { useAPI } from './api'

export function createNewProject(payload) {
  return api.post('/project/create', payload)
}

export function createNewContract(payload) {
  return api.post('/contract/create', payload)
}

export function addNewContract(payload) {
  return api.post('/contract/add', payload)
}

export function getProjects() {
  return api.get('/project')
}

export function useProjects() {
  return useAPI('/project')
}

export function deleteProject(payload) {
  return api.post('/project/delete', payload)
}

export function addToProject(payload) {
  return api.post('/project/contracts/add', payload)
}

export function useContracts() {
  return useAPI('/contract')
}

export function getContracts(payload) {
  return api.post('/contract', payload)
}

export function getProjectFunnelData(payload) {
  return api.post('/contract/funnel', payload)
}

export function getContractsBasedata(payload) {
  return api.post('/contract/basedata', payload)
}

export function getNRUsersData(payload) {
  return api.post('/contract/nrusers', payload)
}

export function createNewList(payload) {
  return api.post('/contract/list/create', payload)
}

export function getProjectList(payload) {
  return api.post('/contract/list', payload)
}

export function deleteProjectList(payload) {
  return api.post('/contract/list/delete', payload)
}

export function renameProjectList(payload) {
  return api.post('/contract/list/rename', payload)
}

export function duplicateProjectList(payload) {
  return api.post('/contract/list/duplicate', payload)
}

export function enableProjectList(payload) {
  return api.post('/contract/list/enable', payload)
}

export function getProjectListWallets(payload) {
  return api.post('/contract/list/wallets', payload)
}

export function addWalletsToList(payload) {
  return api.post('/contract/list/add', payload)
}

export function getWalletUserData(payload) {
  return api.post('/contract/wallet', payload)
}

export function getWalletNftsMInted(payload) {
  return api.post('/contract/nft/allminted', payload)
}

export function getWalletNfts(payload) {
  return api.post('/contract/nft/all', payload)
}

export function getProjectNfts(payload) {
  return api.post('/nft/getall', payload)
}

export function getWalletTokenBalances(payload) {
  return api.post('/contract/address/tokens', payload)
}

export function getContractData(payload) {
  return api.post('/contract/address/type', payload)
}

export function getAllChats(payload) {
  return api.post('/chat/admin/all', payload)
}

export function getMesagesChat(payload) {
  return api.post('/chat/admin/single', payload)
}

export function postMesageChat(payload) {
  return api.post('/chat/admin/singlepost', payload)
}

export function getNftData(payload) {
  return api.post('/contract/nft/data', payload)
}

// export function createNft(payload) {
//   return api.post('/nft/create', payload, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//   })
// }

export function createNft(payload) {
  return api.post('/nft/create', payload)
}

export function updateNft(payload) {
  return api.post('/nft/update', payload)
}

export function getNftDraftData(payload) {
  return api.post('/nft/getdata', payload)
}

export function getCustomMintCollections() {
  return api.get('/nft/mint/getcolections')
}

export function getCustomTicketingCollections() {
  return api.get('/nft/ticket/getcolections')
}

export function getContractAllData(payload) {
  return api.post('/contract/data', payload)
}

export function getNftMetadata(payload) {
  return api.post('/contract/nft/metadata', payload)
}

export function getNftLazyMint(payload) {
  return api.post('/nft/lazymint/getall', payload)
}

export function setNftLazyMint(payload) {
  return api.post('/nft/lazymint/set', payload)
}

export function setNftLazyMintList(payload) {
  return api.post('/nft/lazymint/listset', payload)
}

export function getTicketDraftData(payload) {
  return api.post('/ticket/getdata', payload)
}

export function createTicket(payload) {
  return api.post('/ticket/create', payload)
}

export function updateTicket(payload) {
  return api.post('/ticket/update', payload)
}

export function createNewEvent(payload) {
  return api.post('/ticket/event/create', payload)
}

export function getProjectEvents(payload) {
  return api.post('/ticket/event/getall', payload)
}

export function deleteEvent(payload) {
  return api.post('/ticket/event/delete', payload)
}

export function getEventData(payload) {
  return api.post('/ticket/event/get', payload)
}

export function setTicketSale(payload) {
  return api.post('/ticket/sale/set', payload)
}

export function getTicketInfo(payload) {
  return api.post('/ticket/sale/getdata', payload)
}

export function updateEventData(payload) {
  return api.post('/ticket/event/update', payload)
}

export function adminLoginScan(payload) {
  return api.post('/ticket/event/admin/login', payload)
}

export function adminGetEvent(payload) {
  return api.post('/ticket/event/admin/getevent', payload)
}

export function adminScanTicket(payload) {
  return api.post('/ticket/event/admin/scan', payload)
}

export function saleAdd(payload) {
  return api.post('/ticket/sale/add', payload)
}

export function createMetadata(payload, contract) {
  return api.post(`/contract/create/metadata/${contract}`, payload)
}

export function updateMetadata(payload, contract) {
  return api.post(`/contract/update/metadata/${contract}`, payload)
}

export function getMetadataEdit(payload) {
  return api.post(`/contract/get/metadata`, payload)
}

export function getProjectSaleData(payload) {
  return api.post('/project/saledata', payload)
}

export function getProjectSaleDataSales(payload) {
  return api.post('/project/saledatasales', payload)
}