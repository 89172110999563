import PersonIcon from '@mui/icons-material/Person'
import IconButton from '@mui/material/IconButton'
import { Link } from 'react-router-dom'

const menuId = 'profile-menu'

const ProfileMenu = () => {
  return (
    <Link to="/account-settings">
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
      >
        <PersonIcon sx={{ fontSize: 45, color: 'var(--white)' }} />
      </IconButton>
    </Link>
  )
}

export default ProfileMenu
