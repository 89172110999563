import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Popover from '@mui/material/Popover'
import { Box } from '@mui/system'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGlobalState } from 'state-pool'

import { ContainedButton } from '../../../../components/Buttons'
import CustomCheckBox from '../../../../components/CustomCheckBox'
import CustomLoadingBar from '../../../../components/CustomLoadingBar'
import { useProjects } from '../../../../services/projectServices'
import { globalProject,projectsStore } from '../../../../store'
import {
  getActiveProjectId,
  removeActiveProjectId,
  setActiveProjectId,
  setActiveProjectWallet,
} from '../../../../utils/cookie'
import {
  ContentTitle,
  EmptyProject,
  ItemContent,
  PlaceholderWrapper,
  PopButtonWrapper,
  PopContent,
  Root,
} from './styles'

const ProjectSelector = (props) => {
  const navigate = useNavigate()
  const projectId = getActiveProjectId()
  const [, , updateProjectsData] = useGlobalState(projectsStore)
  const [, , updateGlobalProject] = useGlobalState(globalProject)

  const { isReset, onChange } = props

  const [checked, setChecked] = useState(-1)

  const { data } = useProjects()
  const projectsData = useMemo(() => data.data || [], [data.data])

  useEffect(() => {
    updateProjectsData(() => projectsData)
  }, [projectsData, updateProjectsData])

  useEffect(() => {
    console.log("isReset: "+isReset)
    if (isReset) {
      setChecked(-1)
      removeActiveProjectId()
    }
  }, [isReset])

  useEffect(() => {
    console.log("onChange: "+onChange)
    // if (isReset) {
    //   setChecked(-1)
    //   removeActiveProjectId()
    // }
  }, [onChange])

  useEffect(() => {
    if (projectId && projectsData.length > 0) {
      const id = projectsData.findIndex((obj) => obj.id === projectId)
      setChecked(id)
    }
  }, [projectId, projectsData])

  const onSelectProject = (index) => {
    console.log('data of project')
    console.log(projectsData)
    const id = projectsData[index]?.id
    const wallet = projectsData[index]?.wallet
    setActiveProjectId([id])

    updateGlobalProject(id)
    setActiveProjectWallet([wallet])

    onChange && onChange(index)
    navigate('/dashboard')
  }

  return (
    <PopupState variant="popover" popupId="project-selector">
      {(popupState) => {
        return (
          <Root>
            <Button
              variant="text"
              {...bindTrigger(popupState)}
              sx={{ padding: 0, borderRadius: 0 }}
            >
              {!popupState.isOpen && (
                <PopButtonWrapper>
                  <span>
                    {console.log(checked)}
                    {checked >= 0 && projectsData?.length > 0
                      ? projectsData[checked].name
                      : 'Select Project'}{' '}
                    <ExpandMoreIcon />
                  </span>
                  {checked >= 0 && projectsData?.length > 0 && (
                    <span className="wallet-id">Nr. contracts: {projectsData[checked].contracts.length}</span>
                  )}
                </PopButtonWrapper>
              )}
              {popupState.isOpen && (
                <PlaceholderWrapper>
                  Select Project <ExpandLessIcon />
                </PlaceholderWrapper>
              )}
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {/* <PopContent>
                <ContentTitle>Projects</ContentTitle>
                {data.isLoading && <CustomLoadingBar />}
                {!data.isLoading && (
                  <List disablePadding sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
                    {projectsData.map((project, index) => {
                      const labelId = `project-${index}`
                      return (
                        <ListItem
                          key={index}
                          disablePadding
                          secondaryAction={<Link to="/project-edit">Edit</Link>}
                        >
                          <ListItemButton
                            role={undefined}
                            onClick={() => {
                              popupState.close()
                              onSelectProject(index)
                            }}
                            dense
                            sx={{ background: index % 2 === 1 ? 'var(--whisper)' : 'none' }}
                          >
                            <ItemContent>
                              <div className="project-checkbox">
                                <CustomCheckBox
                                  edge="start"
                                  checked={checked === index}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </div>
                              <div className="project-info">
                                <div className="project-name">{project.name}</div>
                                <div className="project-wallet">Wallet ID: {project.wallet}</div>
                              </div>
                            </ItemContent>
                          </ListItemButton>
                        </ListItem>
                      )
                    })}
                    {projectsData.length === 0 && <EmptyProject>No project</EmptyProject>}
                  </List>
                )}
                <Box pl="1.25rem" mt="0.5rem">
                  <ContainedButton onClick={() => navigate('/new-project')} size="small">
                    New Project
                  </ContainedButton>
                </Box>
              </PopContent> */}


              <PopContent>
                {data.isLoading && <CustomLoadingBar />}
                {(checked >= 0 && projectsData?.length > 0 && projectsData[checked].contracts?.length > 0) && (
                  <List disablePadding sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
                    {projectsData[checked].contracts.map((project, index) => {
                      // const labelId = `project-${index}`
                      return (
                        <ListItem
                          key={index}
                          disablePadding
                        >
                          <ListItemButton
                            role={undefined}
                            dense
                            sx={{ background: index % 2 === 1 ? 'var(--whisper)' : 'none' }}
                          >
                            <ItemContent>
                              <div className="project-info">
                                <div className="project-name">{project?.name?project.name:''}</div>
                                <div className="project-wallet">Wallet: {project?.address?project.address:''}</div>
                              </div>
                            </ItemContent>
                          </ListItemButton>
                        </ListItem>
                      )
                    })}
                    {projectsData.length === 0 && <EmptyProject>No project</EmptyProject>}
                  </List>
                )}
              </PopContent>
            </Popover>
          </Root>
        )
      }}
    </PopupState>
  )
}

export default ProjectSelector
