import styled from 'styled-components'

export const Root = styled.div`
  position: relative;
`

export const PopButtonWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 4.375rem;
  padding: 0.3rem 1rem 0 1.125rem;
  text-transform: capitalize;
  color: var(--white);
  font-family: var(--font-family-ttcommons-bold);
  font-size: var(--font-size-xxl);

  & span {
    line-height: 1.25;
    display: flex;
    align-items: center;

    svg {
      font-size: 29px;
      margin-left: 0.875rem;
      margin-top: -0.3125rem;
    }
  }

  & span.wallet-id {
    opacity: 0.5;
    color: var(--athens-gray);
    font-family: var(--font-family-ttcommons-regular);
    font-size: var(--font-size-m);
    font-weight: 500;
  }
`

export const PlaceholderWrapper = styled.span`
  display: flex;
  align-items: center;
  background-color: var(--athens-gray-1);
  min-height: 4.375rem;
  padding: 0.3rem 1rem 0 1.125rem;
  color: var(--white);
  font-family: var(--font-family-ttcommons-bold);
  font-size: var(--font-size-xxl);
  text-transform: capitalize;

  svg {
    font-size: 29px;
    margin-left: 0.875rem;
    margin-top: -0.3125rem;
  }
`

export const PopContent = styled.div`
  min-width: 27rem;
  padding: 1.25rem 0;
  color: var(--bunting);

  .MuiListItemButton-root {
    padding: 0.75rem 1.25rem;
  }

  .MuiListItemSecondaryAction-root {
    top: 30%;

    a {
      text-decoration: underline;
      color: var(--bunting);
      font-size: var(--font-size-m);

      &:hover {
        text-decoration: none;
      }
    }
  }
`

export const ContentTitle = styled.h1`
  margin: 5px 0;
  padding: 0 1.25rem;
  font-family: var(--font-family-ttcommons-bold);
  font-size: var(--font-size-xxl);
`

export const ItemContent = styled.div`
  flex: 1;
  display: flex;
  cursor: pointer;

  .project-checkbox {
    margin-right: 0.56rem;

    .MuiCheckbox-root {
      margin-top: -3px;
    }
  }

  .project-info {
    flex: 1;
    margin-right: 1rem;

    .project-name {
      font-family: var(--font-family-ttcommons-bold);
      font-size: var(--font-size-l);
      margin-bottom: 0.5rem;
      text-transform: capitalize;
    }

    .project-wallet {
      opacity: 0.25;
      font-family: var(--font-family-ttcommons-regular);
      font-size: var(--font-size-m);
      font-weight: 500;
    }
  }
`

export const EmptyProject = styled.div`
  padding: 1.25rem 0;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--manatee);
`
