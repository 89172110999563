import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const StyledButton = styled(LoadingButton)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  background: 'var(--persian-pink)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '6.5rem',
  height: '3.125rem',
  paddingTop: '0.625rem',
  color: 'var(--white)',
  fontSize: 'var(--font-size-xl)',
  fontFamily: 'var(--font-family-ttcommons-bold)',
  fontWeight: '700',
  borderRadius: 0,
  borderTopRightRadius: 5,
  borderBottomRightRadius: 5,
  textTransform: 'none',
  whiteSpace: 'nowrap',
  boxShadow: 'none',
}))

export const LetsGoButton = (props) => {
  const { children, ...rest } = props

  return (
    <StyledButton variant="contained" color="secondary" {...rest}>
      {children}
    </StyledButton>
  )
}

export const AirDropButton = (props) => {
  const { children, ...rest } = props

  return (
    <StyledButton2 variant="outlined" color="primary" {...rest}>
      {children}
    </StyledButton2>
  )
}

const StyledButton1 = styled(LoadingButton)(() => ({
  height: 50,
  padding: '14px 20px 10px',
  color: 'var(--white)',
  fontFamily: 'var(--font-family-ttcommons-bold)',
  fontSize: 'var(--font-size-xl)',
  whiteSpace: 'nowrap',
}))

export const ContainedButton = (props) => {
  const { children, size, ml, mr, mt, bg, isOpacity, textTransform, ...rest } = props

  return (
    <StyledButton1
      variant="contained"
      sx={{
        backgroundColor: bg || 'var(--jacksons-purple)',
        opacity: isOpacity ? 0.25 : 1,
        marginLeft: ml,
        marginRight: mr,
        marginTop: mt,
        height: size === 'small' ? '2.5rem' : '3.125rem',
        textTransform: textTransform || 'capitalize',
      }}
      {...rest}
    >
      {children}
    </StyledButton1>
  )
}

const StyledButton2 = styled(LoadingButton)(() => ({
  height: 50,
  padding: '14px 20px 10px',
  border: '2px solid',
  fontFamily: 'var(--font-family-ttcommons-bold)',
  fontSize: 'var(--font-size-xl)',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  '&:hover': {
    border: '2px solid var(--jacksons-purple)',
  },
}))

export const OutlineButton = (props) => {
  const { children, size, ml, mr, isLightBorder, ...rest } = props

  return (
    <StyledButton2
      variant="outlined"
      sx={{
        marginLeft: ml,
        marginRight: mr,
        height: size === 'small' ? '2.5rem' : '3.125rem',
        borderColor: isLightBorder ? 'var(--athens-gray)' : 'var(--jacksons-purple)',
        color: isLightBorder ? 'var(--bounting)' : 'var(--jacksons-purple)',
      }}
      {...rest}
    >
      {children}
    </StyledButton2>
  )
}

export const OutlineButtonWhite = (props) => {
  const { children, size, ml, mr, isLightBorder, ...rest } = props

  return (
    <StyledButton2
      variant="outlined"
      sx={{
        marginLeft: ml,
        marginRight: mr,
        height: size === 'small' ? '2.5rem' : '3.125rem',
        borderColor: isLightBorder ? 'var(--athens-gray)' : 'var(--jacksons-purple)',
        color: isLightBorder ? 'var(--athens-gray)' : 'var(--jacksons-purple)',
      }}
      {...rest}
    >
      {children}
    </StyledButton2>
  )
}

const StyledButton3 = styled(LoadingButton)(() => ({
  maxHeight: '3.125rem',
  marginTop: '1.875rem',
  padding: '1rem 1.25rem 0.8rem',
  color: 'var(--athens-gray)',
  fontFamily: 'var(--font-family-ttcommons-bold)',
  borderRadius: 5,
  border: '2px solid var(--athens-gray)',
  fontSize: 'var(--font-size-xl)',
  textTransform: 'none',

  '&:hover': {
    background: 'var(--persian-pink)',
  },
}))

export const WhiteButton = (props) => {
  const { children, ...rest } = props

  return (
    <StyledButton3 variant="outlined" {...rest}>
      {children}
    </StyledButton3>
  )
}
